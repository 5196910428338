export const benefits = [
  {
    image: '/images/ic-benefits-1.svg',
    title: 'Availability',
    description: 'Discover tutors not only from your city. Finding the right tutor for your needs has become much easier.',
  },
  {
    image: '/images/ic-benefits-2.svg',
    title: 'Selection',
    description: 'When searching for online tutors, you have a much better chance of choice, which allows you to be choosy. In addition, a wider selection also gives you the opportunity to work with several teachers specializing in various fields.',
  },
  {
    image: '/images/ic-benefits-3.svg',
    title: 'Value',
    description: 'Tutors must rely on merit, not scarcity, to win your business, and you do not need to settle for a second bet. This puts you in the driver\'s seat when teachers must fight for your business, and not vice versa.',
  },
  {
    image: '/images/ic-benefits-4.svg',
    title: 'Convenience',
    description: 'Add to this the convenience of the opportunity to meet with a tutor without leaving your home. If you insist on a personal meeting with a tutor, then he spends time and money on the way. In addition, a meeting usually means that you need to change the atmosphere.',
  },
  {
    image: '/images/ic-benefits-5.svg',
    title: 'Accessibility',
    description: 'Convenience assumes that you have a choice, but what if you don’t actually have any options for local tutoring? For some, making the decision to seek tutoring online may mean the difference between getting help and not.',
  },
  {
    image: '/images/ic-benefits-6.svg',
    title: 'Technology',
    description: 'Anyone who is used to chatting on Skype and isn’t afraid of new software, such training provides many advantages. Online sessions are easily recorded for future reference. A good teacher will use the technology for illustrative purposes, using photographs, animation, drawings and diagrams.',
  },
];

export const howItWorks = [
  {
    image: '/images/pic-howitworks-1.png',
    title: 'Find the perfect tutor',
    description: 'Search by hourly rate, highest rating or years of experience. Our tutors are vetted to ensure expert guidance. Find a tutor that is the best fit for your learning style and academic needs.',
  },
  {
    image: '/images/pic-howitworks-2.png',
    title: 'Schedule your lessons',
    description: 'View your tutors availability and pick the perfect time for your busy schedule. Schedule one-on-one or group online lessons.',
  },
  {
    image: '/images/pic-howitworks-3.png',
    step: '/images/pic-number-3.svg',
    title: 'Take your first lesson',
    description: 'Talk with your tutor about the skills you need and get a personalized plan to achieve your goals',
  },
];
