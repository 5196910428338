import { themeVariables } from '../../styles/themeVariables';

export const studyWithLernStyle = theme => ({
  imageContainer: {
    margin: '0 auto',
  },
  subtitleContainer: {
    margin: '0 auto',
    width: '70%',
    textAlign: 'center',
  },
  button: {
    marginTop: '24px',
    textDecoration: 'none',
    color: themeVariables.colors.white,
    width: '240px',
    height: '48px',
    borderRadius: '4px',
    boxShadow: '0 8px 12px 0 rgba(0, 182, 183, 0.24), 0 4px 12px 0 rgba(0, 182, 183, 0.12)',
    backgroundColor: '#00b6b7',
    marginBottom: '18px',
    '&:hover': {
      backgroundColor: themeVariables.colors.primaryPale
    },
  },
  signUp: {
    color: themeVariables.colors.primaryLight,
    textDecorationColor: themeVariables.colors.primaryLight,
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
});
