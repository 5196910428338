import React from 'react';
import { withStyles, Grid, Typography, Button } from '@material-ui/core';
import * as classnames from 'classnames';

import { Link, withTranslation } from '../../helpers/i18n';
import { generalStyles } from '../../styles/generalStyles';
import { findTutorStyle } from './findTutorStyles';
import combineStyles from '../../helpers/style';
import { FIND_TUTORS_ROUTE } from '../../routes';

const FindTutor = ({ t, classes }) => {
  return (
    <Grid className={classnames(classes.width100, classes.homepageSectionMargin)}>
      <Grid container spacing={3} className={classes.customContainer}>
        <Grid item xs={12} className={classes.content}>
          <Grid item xs={12} md={4} lg={4} className={classnames(classes.textCenter, classes.findTutorContent)}>
            <Typography variant="h3" color="primary" gutterBottom>
              Find a tutor today!
            </Typography>
            <Typography variant="subtitle1" className={classes.descriptionText}>
              Learn online with the best tutors.
            </Typography>
            <Typography variant="subtitle1" className={classes.descriptionText} gutterBottom>
              100% Satisfaction Guarantee!
            </Typography>
            <Link href={FIND_TUTORS_ROUTE}>
              <a style={{ textDecoration: 'none' }}>
                <Button color="secondary" type="button" className={classes.button}>
                  FIND A TUTOR
                </Button>
              </a>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FindTutor.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const combinedStyles = combineStyles(generalStyles, findTutorStyle);
export default withTranslation('common')(withStyles(combinedStyles)(FindTutor));
