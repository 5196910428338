import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import * as classnames from 'classnames';

import { withTranslation } from '../../helpers/i18n';
import { generalStyles } from '../../styles/generalStyles';
import { howItWorksStyle } from './howItWorksStyle';
import { howItWorks } from '../../helpers/homepageStaticText';
import combineStyles from '../../helpers/style';

const HowItWorks = ({ t, classes, modal }) => {
  const renderContent = () => {
    return howItWorks.map((item, i) => {
      return (
        <Grid item xs={12} key={i} className={classes.itemContainer}>
          <Grid container className={classes.alignContentCenter}>
            <Grid item xs={12} md={5} className={classes.leftSection}>
              <Grid className={classes.step}>
                <Typography variant="h4" color="primary">
                  {i + 1}
                </Typography>
              </Grid>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {item.description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <img src={item.image} width="100%" height="100%" />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid className={classnames(classes.width100, classes.homepageSectionMargin)}>
      <Grid container spacing={3} className={classes.customContainer}>
        <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="h2" color="textPrimary" id="howitworks">
            How it works
          </Typography>
        </Grid>
        {renderContent()}
      </Grid>
    </Grid>
  );
};

HowItWorks.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const combinedStyles = combineStyles(generalStyles, howItWorksStyle);
export default withStyles(combinedStyles, { withTheme: true })(withTranslation('common')(HowItWorks));
