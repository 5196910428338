import { themeVariables } from '../../styles/themeVariables';

export const howItWorksStyle = theme => ({
  itemContainer: {
    backgroundColor: themeVariables.colors.background,
    borderRadius: '16px',
    marginTop: '30px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '16px',
    },
  },
  leftSection: {
    padding: '36px 40px',
    [theme.breakpoints.only('xs')]: {
      padding: '24px',
    },
  },
  step: {
    borderRadius: '50%',
    border: '2px solid ' + themeVariables.colors.primaryPale,
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '36px',
  },
});
