import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import { i18n, Link, withTranslation } from '../../../common/helpers/i18n';
import combineStyles from '../../../common/helpers/style';
import { generalStyles } from '../../../common/styles/generalStyles';
import { searchBannerStyles } from './searchBannerStyles';
import AutocompleteComponent from '../autocomplete/autocomplete';
import {searchSubjects, setSearchSubjects} from '../../actions/tutor';

const SearchBanner = ({ t, classes, searchedSubjects, setSearchSubjects, searchSubjects }) => {
  const searchTutorsAutocomplete = (value) => {
    if (value.length >= 3) {
      searchSubjects({
        search: value,
        simplified: true,
      });
    } else {
      setSearchSubjects([]);
    }
  };

  return (
    <Grid className={`${classes.greyBg} ${classes.width100}`}>
      <Grid container className={classes.customContainer}>
        <Grid item xs={12} sm={5} className={classes.mainTxtWrapper}>
          <p className={classes.mainTitle}>
            <span>{t('Online')}</span>
            <span>{t('Tutoring')}</span>
          </p>
          <p className={classes.mainSubtitle}>
            <span>{t('We help you find expert tutors at a price that works for you, with no additional fees.')}</span>
            <span>{t('Choose the subject and set your own schedule.')}</span>
          </p>
          <AutocompleteComponent
            searchTutors={searchTutorsAutocomplete}
            searchResults={searchedSubjects}
          />
        </Grid>
        <Grid item xs={12} sm={7} className={classes.mainBanner} />
      </Grid>
    </Grid>
  );
};

SearchBanner.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const mapStateToProps = (state) => ({
  searchedSubjects: state.tutor.searchSubjects,
});

const combinedStyles = combineStyles(generalStyles, searchBannerStyles);

export default withTranslation('common')(withStyles(combinedStyles, { withTheme: true })(connect(
  mapStateToProps, { searchSubjects, setSearchSubjects }
)(SearchBanner)));
