import React from 'react';
import { Grid } from '@material-ui/core';

import { withTranslation } from '../common/helpers/i18n';
import combineStyles from '../common/helpers/style';
import { generalStyles } from '../common/styles/generalStyles';
import Menu from '../common/components/menu/menu';
import Footer from '../common/components/footer/footer';
import SearchBanner from '../common/components/searchBanner/searchBanner';
import HowItWorks from '../common/components/howItWorks/howItWorks';
import Subjects from '../common/components/subjects/subjects';
import FindTutor from '../common/components/findTutor/findTutor';
import StudyWithLern from '../common/components/studyWithLern/studyWithLern';
import Router from "next/router";
import withFBQ from "next-fbq";
import { compose } from 'redux'

const Index = ({ t, classes, modal }) => {
  return (
    <Grid>
      <Menu />
      <SearchBanner />
      {/* <Benefits /> */}
      <HowItWorks />
      <Subjects />
      <FindTutor />
      <StudyWithLern />
      <Footer />
    </Grid>
  );
};

Index.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});
const combinedStyles = combineStyles(generalStyles);

let indexComponent;
if (process.env.NODE_ENV === 'production' && process.browser) {
   indexComponent = compose(
    withFBQ(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, Router),
    withTranslation('common')
  )(Index);
} else {
  indexComponent = compose(
    withTranslation('common')
  )(Index);
}
export default indexComponent;

