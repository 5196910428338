export const GET_TUTOR = 'GET_TUTOR';
export const GET_TUTOR_BY_SLUG = 'GET_TUTOR_BY_SLUG';
export const SET_TUTOR = 'SET_TUTOR';
export const EDIT_TUTOR = 'EDIT_TUTOR';
export const GET_AVAILABILITY_INTERVALS = 'GET_AVAILABILITY_INTERVALS';
export const SET_AVAILABILITY_INTERVALS = 'SET_AVAILABILITY_INTERVALS';
export const GET_TUTOR_SCHEDULE = 'GET_TUTOR_SCHEDULE';
export const SET_TUTOR_SCHEDULE = 'SET_TUTOR_SCHEDULE';
export const ADD_CLASS = 'ADD_CLASS';
export const EDIT_CLASS = 'EDIT_CLASS';
export const GET_AVAILABILITY_INTERVALS_BY_SLUG = 'GET_AVAILABILITY_INTERVALS_BY_SLUG';
export const ADD_TUTOR_AVAILABILITY = 'ADD_TUTOR_AVAILABILITY';
export const SELECT_AVAILABILITY = 'SELECT_AVAILABILITY';
export const SET_SELECTED_AVAILABILITY = 'SET_SELECTED_AVAILABILITY';
export const EDIT_AVAILABILITY = 'EDIT_AVAILABILITY';
export const SELECT_CLASS = 'SELECT_CLASS';
export const SET_SELECTED_CLASS = 'SET_SELECTED_CLASS';
export const GET_TUTOR_STUDENTS = 'GET_TUTOR_STUDENTS';
export const SET_TUTOR_STUDENTS = 'SET_TUTOR_STUDENTS';
export const GET_TUTOR_SUBJECTS = 'GET_TUTOR_SUBJECTS';
export const SET_TUTOR_SUBJECTS = 'SET_TUTOR_SUBJECTS';
export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_AVAILABILITY = 'DELETE_AVAILABILITY';
export const SEARCH_TUTORS = 'SEARCH_TUTORS';
export const SET_SEARCH_TUTORS = 'SET_SEARCH_TUTORS';
export const SET_SEARCH_SUBJECTS = 'SET_SEARCH_SUBJECTS';
export const SET_SEARCH_TUTOR_FILTERS = 'SET_SEARCH_TUTOR_FILTERS';
export const SEARCH_TUTORS_FROM_SEARCH_PAGE = 'SEARCH_TUTORS_FROM_SEARCH_PAGE';
export const PUBLISH_PROFILE_ACTION = 'PUBLISH_PROFILE_ACTION';
export const GET_STRIPE_CONNECT_URL = 'GET_STRIPE_CONNECT_URL';
export const SET_STRIPE_CONNECT_URL = 'SET_STRIPE_CONNECT_URL';
export const AUTHORISE_STRIPE_ACCOUNT = 'AUTHORISE_STRIPE_ACCOUNT';
export const SEARCH_SUBJECTS = 'SEARCH_SUBJECTS';
export const GET_TUTOR_REVIEWS = 'GET_TUTOR_REVIEWS';
export const SET_TUTOR_REVIEWS = 'SET_TUTOR_REVIEWS';
export const GET_TUTOR_REVIEWS_BY_SLUG = 'GET_TUTOR_REVIEWS_BY_SLUG';
export const SET_FEE_INFO = 'SET_FEE_INFO';
export const GET_SURVEY_BY_SUBJECT = 'GET_SURVEY_BY_SUBJECT';
export const SET_SURVEY = 'SET_SURVEY';
export const SUBMIT_SURVEY_FOR_VALIDATION = 'SUBMIT_SURVEY_FOR_VALIDATION';
export const GET_TUTOR_DASHBOARD = 'GET_TUTOR_DASHBOARD';
export const SET_TUTOR_DASHBOARD = 'SET_TUTOR_DASHBOARD';
export const SET_NEW_CLASS = 'SET_NEW_CLASS';
export const UPDATE_CLASS = 'UPDATE_CLASS';

export const getTutorBySlugAction = (payload) => ({
  type: GET_TUTOR_BY_SLUG,
  payload,
});

export const getTutorAction = (payload) => ({
  type: GET_TUTOR,
  payload,
});

export const setTutorAction = (payload) => ({
  type: SET_TUTOR,
  payload,
});

export const editTutorProfileAction = (payload) => ({
  type: EDIT_TUTOR,
  payload,
});

export const getAvailabilityIntervalsAction = (payload) => ({
  type: GET_AVAILABILITY_INTERVALS,
  payload,
});

export const setAvailabilityIntervalsAction = (payload) => ({
  type: SET_AVAILABILITY_INTERVALS,
  payload,
});

export const getTutorScheduleAction = (payload) => ({
  type: GET_TUTOR_SCHEDULE,
  payload,
});

export const setTutorScheduleAction = (payload) => ({
  type: SET_TUTOR_SCHEDULE,
  payload,
});

export const addClassAction = (payload) => ({
  type: ADD_CLASS,
  payload,
});

export const editClassAction = (payload) => ({
  type: EDIT_CLASS,
  payload,
});

export const getAvailabilityIntervalsBySlug = (payload) => ({
  type: GET_AVAILABILITY_INTERVALS_BY_SLUG,
  payload,
});

export const addTutorAvailabilityAction = (payload) => ({
  type: ADD_TUTOR_AVAILABILITY,
  payload,
});

export const selectAvailability = (payload) => ({
  type: SELECT_AVAILABILITY,
  payload,
});

export const setSelectedAvailability = (payload) => ({
  type: SET_SELECTED_AVAILABILITY,
  payload,
});

export const editAvailability = (payload) => ({
  type: EDIT_AVAILABILITY,
  payload,
});

export const getTutorStudentsAction = () => ({
  type: GET_TUTOR_STUDENTS,
});

export const setTutorStudentsAction = (payload) => ({
  type: SET_TUTOR_STUDENTS,
  payload,
});

export const getTutorSubjectsAction = () => ({
  type: GET_TUTOR_SUBJECTS,
});

export const setTutorSubjectsAction = (payload) => ({
  type: SET_TUTOR_SUBJECTS,
  payload,
});

export const selectClassAction = (payload) => ({
  type: SELECT_CLASS,
  payload,
});

export const setSelectedClassAction = (payload) => ({
  type: SET_SELECTED_CLASS,
  payload,
});

export const deleteClassAction = (payload) => ({
  type: DELETE_CLASS,
  payload,
});

export const deleteAvailability = (payload) => ({
  type: DELETE_AVAILABILITY,
  payload,
});

export const searchTutors = (payload) => ({
  type: SEARCH_TUTORS,
  payload,
});

export const searchSubjects = (payload) => ({
  type: SEARCH_SUBJECTS,
  payload,
});

export const setSearchTutors = (payload) => ({
  type: SET_SEARCH_TUTORS,
  payload,
});

export const setSearchSubjects = (payload) => ({
  type: SET_SEARCH_SUBJECTS,
  payload,
});

export const setSearchTutorsFiltersAction = (payload) => ({
  type: SET_SEARCH_TUTOR_FILTERS,
  payload,
});

export const searchTutorsFromSearchPage = (payload) => ({
  type: SEARCH_TUTORS_FROM_SEARCH_PAGE,
  payload,
});

export const publishProfileAction = () => ({
    type: PUBLISH_PROFILE_ACTION,
});

export const authoriseStripeAccountAction = () => ({
  type: AUTHORISE_STRIPE_ACCOUNT,
});

export const getTutorReviewsAction = () => ({
  type: GET_TUTOR_REVIEWS,
});

export const getTutorReviewsBySlugAction = (payload) => ({
  type: GET_TUTOR_REVIEWS_BY_SLUG,
  payload,
});

export const setTutorReviewsAction = (payload) => ({
  type: SET_TUTOR_REVIEWS,
  payload,
});

export const getStripeConnectUrlsAction = () => ({
  type: GET_STRIPE_CONNECT_URL,
});

export const setStripeConnectUrlsAction = (payload) => ({
  type: SET_STRIPE_CONNECT_URL,
  payload,
});

export const setFeeInfoAction = (payload) => ({
  type: SET_FEE_INFO,
  payload,
});

export const getSurveyBySubjectAction = (payload) => ({
  type: GET_SURVEY_BY_SUBJECT,
  payload,
});

export const setSurvey = (payload) => ({
  type: SET_SURVEY,
  payload,
});

export const submitSurveyForValidationAction = (payload) => ({
  type: SUBMIT_SURVEY_FOR_VALIDATION,
  payload,
});

export const getTutorDashboardAction = (payload) => ({
  type: GET_TUTOR_DASHBOARD,
  payload,
});

export const setTutorDashboard = (payload) => ({
  type: SET_TUTOR_DASHBOARD,
  payload,
})

export const setNewClassAction = (payload) => ({
  type: SET_NEW_CLASS,
  payload,
})

export const updateClassAction = (payload) => ({
  type: UPDATE_CLASS,
  payload,
})
