import React from 'react';
import { withStyles, Grid, Typography, Button } from '@material-ui/core';
import * as classnames from 'classnames';

import { Link, withTranslation } from '../../helpers/i18n';
import { generalStyles } from '../../styles/generalStyles';
import { studyWithLernStyle } from './studyWithLernStyle';
import combineStyles from '../../helpers/style';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../../routes';

const StudyWithLern = ({ t, classes, subjects }) => {
  return (
    <Grid className={classnames(classes.width100, classes.homepageSectionMargin)}>
      <Grid container spacing={3} className={classes.customContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <Grid>
            <img src="/images/group-29.png" alt="Study with LERN" width="100%" height="100%" />
          </Grid>
          <Grid className={classes.textCenter}>
            <Typography variant="h2" color="textPrimary" gutterBottom>
              Students across the US study with LERN
            </Typography>
          </Grid>
          <Grid className={classes.subtitleContainer}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Learn any subject with professional tutors. Prepare to achieve your academic goals today
            </Typography>
          </Grid>
          <Grid className={classes.textCenter}>
            <Link href={REGISTER_ROUTE}>
              <a style={{ textDecoration: 'none' }}>
                <Button color="secondary" type="button" className={classes.button}>
                  GET STARTED
                </Button>
              </a>
            </Link>
          </Grid>
          <Grid className={classes.textCenter}>
            <Typography variant="caption" color="textSecondary">Already have an account? </Typography>
            <Link href={LOGIN_ROUTE}>
              <a>
                <Typography
                  variant="caption"
                  display="inline"
                  className={classes.signUp}
                >
                  Log In
                </Typography>
              </a>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StudyWithLern.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const combinedStyles = combineStyles(generalStyles, studyWithLernStyle);
export default withTranslation('common')(withStyles(combinedStyles)(StudyWithLern));
