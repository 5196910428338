import { themeVariables } from '../../styles/themeVariables';

export const autocompleteStyles = theme => ({
  input: {
    fontFamily: 'Playfair Display',
    fontSize: '16px',
    backgroundColor: themeVariables.colors.white,
    border: 'none',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
    height: '60px',
    width: '100%',
    padding: '20px 16px',
    color: themeVariables.colors.primaryText,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: themeVariables.colors.tetriaryText,
    },
    [theme.breakpoints.only('xs')]: {
      height: '48px',
    },
  },
  button: {
    width: '132px',
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: themeVariables.colors.mainPale,
    color: themeVariables.colors.white,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    [theme.breakpoints.only('xs')]: {
      width: '48px',
      height: '48px',
    },
    '&:hover': {
      backgroundColor: themeVariables.colors.primaryPale
    },
  },
  displayFlex: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      top: '16px',
      minWidth: '328px',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '16px',
      right: '16px',
    },
  },
  width: {
    width: '100%',
  },
  optionsContainer: {
    backgroundColor: themeVariables.colors.white,
  },
});
