import { themeVariables } from '../../styles/themeVariables';

export const findTutorStyle = theme => ({
  content: {
    background: 'url("/images/pic-find-tutor.png") no-repeat center center',
    backgroundSize: 'contain',
    minHeight: '538px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeVariables.colors.primaryText,
      backgroundImage: 'none',
      minHeight: '100%',
      borderRadius: '16px',
      '& H3': {
        fontSize: '36px',
      },
    },
  },
  descriptionText: {
    color: themeVariables.colors.tetriaryText,
  },
  button: {
    marginTop: '24px',
    textDecoration: 'none',
    color: themeVariables.colors.white,
    width: '240px',
    height: '48px',
    borderRadius: '4px',
    boxShadow: '0 8px 12px 0 rgba(0, 182, 183, 0.24), 0 4px 12px 0 rgba(0, 182, 183, 0.12)',
    backgroundColor: '#00b6b7',
    '&:hover': {
      backgroundColor: themeVariables.colors.primaryPale
    },
  },
  findTutorContent: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '275px',
    },
  }
});
