import React from 'react';
import Router from 'next/router';
import { withStyles, Grid, Button, Hidden } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withTranslation, Link } from '../../helpers/i18n';
import { autocompleteStyles } from './autocompleteStyles';
import { FIND_TUTORS_ROUTE } from '../../routes';

const AutocompleteComponent = ({ t, classes, searchResults, searchTutors }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(searchResults);

  const handleSubmit = (event) => {
    if (inputValue) {
      Router.push({
        pathname: '/tutors',
        query: { search: inputValue },
      });
    }
    event.preventDefault();
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit} className={classes.width}>
        <Grid item xs={12} className={classes.displayFlex}>
          <Autocomplete
            id="autocomplete"
            className={classes.width}
            filterOptions={(x) => x}
            options={searchResults && searchResults.length > 0 ? searchResults : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.full_name)}
            autoComplete
            noOptionsText={null}
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              searchTutors(newInputValue);
              setValue(newInputValue);
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  {...params.inputProps}
                  type="text"
                  placeholder="What do you want to learn?"
                  className={classes.input}
                />
              </div>
            )}
            renderOption={(option) => {
              return (
                <Grid container alignItems="center">
                  <Link href={`${FIND_TUTORS_ROUTE}?search=${escape(option.name)}`} >
                    <Grid item xs>
                      <span>
                        {option.name}
                      </span>
                    </Grid>
                  </Link>
                </Grid>
              );
            }}
          />
          <Hidden xsDown>
            <Button type="submit" color="primary" className={classes.button}>Find tutor</Button>
          </Hidden>
          <Hidden smUp>
            <Button type="submit" color="primary" className={classes.button}>
              <img src="/images/ic-search.svg" width="24px" height="24px" />
            </Button>
          </Hidden>
        </Grid>
      </form>
    </Grid>
  );
};

Autocomplete.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

export default withStyles(autocompleteStyles, { withTheme: true })(withTranslation('common')(AutocompleteComponent));
