export const GET_SUBJECTS = 'GET_SUBJECTS';
export const SET_SUBJECTS = 'SET_SUBJECTS';

export const getSubjectsAction = () => ({
  type: GET_SUBJECTS,
});

export const setSubjectsAction = (payload) => ({
  type: SET_SUBJECTS,
  payload,
});
