import { themeVariables } from '../../styles/themeVariables';

export const subjectsStyle = theme => ({
  card: {
    boxShadow: '0 10px 30px 0 rgba(31, 59, 99, 0.12)',
    borderRadius: '16px',
    backgroundColor: themeVariables.colors.white,
    borderTop: '16px solid',
    height: '100%',
  },
});
