import React, { useEffect, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as classnames from 'classnames';
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  Hidden,
} from '@material-ui/core';

import { Link, withTranslation } from '../../helpers/i18n';
import { generalStyles } from '../../styles/generalStyles';
import { subjectsStyle } from './subjectsStyle';
import combineStyles from '../../helpers/style';
import { getSubjectsAction } from '../../actions/subjects';
import { FIND_TUTORS_ROUTE } from '../../routes';

const Subjects = ({ t, classes, subjects }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubjectsAction());
  }, []);

  const renderContent = () => {
    return subjects.map((subject, i) => {
      return (
        <Fragment>
          {
            i === 3 &&
            <Hidden mdDown>
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <img src="/images/pic-books.svg" width="100%"/>
              </Grid>
            </Hidden>
          }
          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
            <Card style={{ borderColor: subject.color }} className={classes.card}>
              <CardContent>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  {subject.name}
                </Typography>
                {subject.children.map((item, j) => {
                  return (
                    <Link href={`${FIND_TUTORS_ROUTE}?subject=${escape(item.name)}`}>
                      <a className={classes.noUnderlineLinks}>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          key={j}
                          gutterBottom
                        >
                          - {item.name}
                        </Typography>
                      </a>
                    </Link>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        </Fragment>
      );
    });
  };

  return (
    <Grid
      className={classnames(classes.width100, classes.homepageSectionMargin)}
    >
      <Grid container spacing={3} className={classes.customContainer}>
        <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="h2" color="textPrimary" gutterBottom>
            Subjects
          </Typography>
        </Grid>
        {renderContent()}
      </Grid>
    </Grid>
  );
};

Subjects.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const mapStateToProps = state => ({
  subjects: state.subjects,
});

const combinedStyles = combineStyles(generalStyles, subjectsStyle);
export default withTranslation('common')(
  withStyles(combinedStyles)(
    connect(mapStateToProps, { getSubjectsAction })(Subjects)
  )
);
