import { themeVariables } from '../../styles/themeVariables';

export const searchBannerStyles = theme => ({
  mainTxtWrapper: {
    [theme.breakpoints.down('xs')]: {
      order: '2',
      backgroundImage: 'url("/images/pic-header.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      padding: '265px 0 0 0',
      marginTop: '105px',
    },
  },
  mainTitle: {
    fontSize: '96px',
    fontFamily: 'Playfair Display, serif',
    color: themeVariables.colors.mainPale,
    textTransform: 'uppercase',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-1.5px',
    lineHeight: '1.1',
    padding: '0px',
    margin: '110px 0 0 0',
    [theme.breakpoints.down('lg')]: {
      fontSize: '90px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '75px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '55px',
      margin: '30px 0 0 0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
    },

    '& span': {
      display: 'block',
      textTransform: 'uppercase',
    },
  },
  mainBanner: {
    height: '851px',
    backgroundImage: 'url("/images/pic-header.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'top 30px right',
    [theme.breakpoints.down('xs')]: {
      order: '1',
      height: 'auto',
    },
    [theme.breakpoints.only('lg')]: {
      height: '700px',
    },
    [theme.breakpoints.only('md')]: {
      height: '550px',
    },
    [theme.breakpoints.only('sm')]: {
      height: '450px',
    },
  },
  mainSubtitle: {
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    color: themeVariables.colors.secondaryText,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },

    '& span': {
      display: 'block',
    },
  },
});
